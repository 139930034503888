import { useCallback, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import {
  accountSharedService,
  agentCallSharedService,
  autoDialerSharedService,
  CallClassificationEnum,
  cartSharedService,
  ctiSharedService,
  GlobalMissionKeys,
  growSettingsSharedService,
  missionSharedService,
  OpenCallStateEnum,
  phoneRankSharedService,
  PocCallOrigin,
  useFeatureToggleEnabledByOrgId,
  userConfigSharedService,
  useSharedUnit,
} from "@bees-grow-shared/services";
import { useUnit } from "effector-react";
import Moment from "moment";

import {
  CallSummarySchema,
  SubmitCallSummarySchema,
  UseCallSummaryModalProps,
} from "@/@types/CallSummaryTypes";
import { ACCOUNT_PATH, HOME_PATH } from "@/config/constants";
import { GROW_PORTAL_CTI } from "@/config/featureToggles";
import { callEnded } from "@/config/typewriter";
import { callLaterOptions } from "@/constant/callLaterOptions";
import { FinisherButtonEnum } from "@/constant/finisherButtonEnum";
import { useCallTimerInfo } from "@/hooks/useCallTimerInfo/useCallTimerInfo";
import GapStore from "@/stores/gap/GapStore";
import { setIsModalOpen } from "@/stores/openCall/OpenCallEvent";
import OpenCallStore from "@/stores/openCall/OpenCallStore";
import { upsertOpenCallUseCase } from "@/useCases/agentCall/upsertOpenCall/UpsertOpenCallUseCase";
import { calculateCallLaterTime } from "@/utils/calculateCallLaterTime/calculateCallLaterTime";

import { useHandleCallClassification } from "../useHandleCallClassification/useHandleCallClassification";
import { callSummarySchema } from "./schema/callSummarySchema";

export function useCallSummaryModal(): UseCallSummaryModalProps {
  const cartService = cartSharedService();
  const agentCallService = agentCallSharedService();
  const autoDialerService = autoDialerSharedService();
  const phoneRankService = phoneRankSharedService();
  const accountService = accountSharedService();
  const growSettingsService = growSettingsSharedService();
  const userConfigService = userConfigSharedService();
  const ctiService = ctiSharedService();
  const missionService = missionSharedService();

  const { gap } = useUnit(GapStore);

  const { isModalOpen } = useUnit(OpenCallStore);
  const { details } = useSharedUnit(cartService);
  const {
    pocCallOrigin,
    callId,
    callLogs,
    ctiEnabled,
    isCallValid,
    startCallDate,
  } = useSharedUnit(agentCallService);
  const { phoneAutoDialer } = useSharedUnit(autoDialerService);
  const { vendorId, accountId } = useSharedUnit(accountService);
  const { phones } = useSharedUnit(phoneRankService);
  const { orgId } = useSharedUnit(userConfigService);
  const { isConnected: isCTIConnected } = useSharedUnit(ctiService);

  const { missions } = useSharedUnit(missionService);

  const { isEnabled: isCTIEnabled } =
    useFeatureToggleEnabledByOrgId(GROW_PORTAL_CTI);

  const navigate = useNavigate();

  const handleToggleModal = useCallback(() => {
    setIsModalOpen({ isModalOpen: !isModalOpen });
  }, [isModalOpen]);

  const { attempts } = useCallTimerInfo(handleToggleModal);

  const handleCallClassification = useHandleCallClassification(
    phones,
    phoneAutoDialer,
    accountId,
    vendorId,
  );

  const isAutoDialer = useMemo(
    () => pocCallOrigin === PocCallOrigin.AUTO_DIALER,
    [pocCallOrigin],
  );

  const handleCheckCallClassification = useCallback(
    (callClassification: CallClassificationEnum) =>
      CallClassificationEnum.SUCCESS_ON_CONTACT === callClassification,
    [],
  );

  useEffect(() => {
    if (orgId && vendorId) {
      growSettingsService.getGrowSettings({ orgId, vendorId });
    }
  }, [growSettingsService, orgId, vendorId]);

  const handleAutoDialerState = useCallback(
    (data: SubmitCallSummarySchema) => {
      autoDialerService.setAutoDialerState({
        accountIdAutoDialer: "",
        vendorIdAutoDialer: "",
        phoneAutoDialer: "",
      });

      agentCallService.setStartCallState({ hasStartedCall: true });
      if (data.submitter === FinisherButtonEnum.TAKE_BREAK) {
        autoDialerService.setAutoDialerState({ showStopCallingModal: true });
      }
    },
    [autoDialerService, agentCallService],
  );

  const handleCallEnd = useCallback(
    (data: SubmitCallSummarySchema, isSuccessContact: boolean) => {
      const { callClassification, callComment, submitter } = data;

      callEnded({
        call_origin: pocCallOrigin,
        contact_classification: callClassification,
        general_comments: callComment,
        finisher_button: submitter,
      });

      if (isAutoDialer && isSuccessContact && isCallValid) {
        handleCallClassification();
      }

      handleToggleModal();
      agentCallService.resetStoresOnEndCall();

      if (ctiEnabled && isCTIConnected) {
        ctiService.close();
      }

      if (isAutoDialer) {
        handleAutoDialerState(data);
      }

      if (
        data.submitter === FinisherButtonEnum.CALL_NEXT_POC &&
        pocCallOrigin === PocCallOrigin.GET_NEXT_CLIENT
      ) {
        const goToNextPoc = agentCallService
          .getNextPoc({ ctiEnabled: isCTIEnabled })
          .then((response) => {
            navigate(ACCOUNT_PATH);
            return response;
          });

        agentCallService.setGetNextPoc(goToNextPoc);
        return;
      }

      navigate(HOME_PATH);
    },
    [
      pocCallOrigin,
      isAutoDialer,
      isCallValid,
      handleToggleModal,
      agentCallService,
      ctiEnabled,
      isCTIConnected,
      navigate,
      handleCallClassification,
      ctiService,
      handleAutoDialerState,
      isCTIEnabled,
    ],
  );

  const handleSubmitCallSummary = useCallback(
    (data?: CallSummarySchema) => {
      const { callLater, callClassification, callComment } = data;

      const callLaterHour = parseFloat(callLater || "0");
      const callLaterTime = calculateCallLaterTime(callLaterHour);

      const elapsedTime = Math.abs(
        Moment.utc().diff(Moment.utc(startCallDate)),
      );

      const isSuccessContact =
        handleCheckCallClassification(callClassification);

      const hasTopLineGap =
        missions &&
        missions.some(
          (mission) => mission.missionKey === GlobalMissionKeys.TOPLINE,
        ) &&
        isSuccessContact;

      const duration = elapsedTime / 1000;

      const endCall = new Date();

      const requestData = {
        callId,
        vendorId,
        endCall,
        useCase: OpenCallStateEnum.END_CALL,
        duration,
        callClassification,
        callComment,
        callLaterHour,
        callLaterTime,
        callLogs,
        gapTotalValue: hasTopLineGap ? gap : 0,
      };

      upsertOpenCallUseCase(requestData)
        .then(() => {
          handleCallEnd(data, isSuccessContact);
        })
        .catch(() => {
          handleToggleModal();
          agentCallService.setStartCallState({ openCall: { error: true } });
        });
    },
    [
      startCallDate,
      callId,
      vendorId,
      callLogs,
      handleCallEnd,
      handleToggleModal,
      agentCallService,
      gap,
      handleCheckCallClassification,
      missions,
    ],
  );

  return {
    isModalOpen,
    ctiEnabled,
    callTimerInfo: { attempts, isCTIConnected },
    cartDetails: details,
    callLaterOptions,
    pocCallOrigin,
    callSummarySchema,
    actions: {
      handleToggleModal,
      handleSubmitCallSummary,
    },
  };
}
