import {
  agentCallSharedService,
  CallClassificationEnum,
  CallLogs,
  missionSharedService,
  OpenCallStateEnum,
} from "@bees-grow-shared/services";

import { CallMission } from "@/services/agentCall/upsertOpenCall/model/UpsertOpenCallRequest";
import { upsertOpenCall } from "@/services/agentCall/upsertOpenCall/UpsertOpenCallService";

interface UpsertOpenCallUseCaseProps {
  callId: string;
  vendorId: string;
  useCase: OpenCallStateEnum;
  startCall?: Date;
  endCall?: Date;
  duration?: number;
  callClassification?: CallClassificationEnum;
  callComment?: string;
  callLaterHour?: number;
  callLaterTime?: string;
  callLogs?: CallLogs[];
  gapTotalValue?: number;
}

export const upsertOpenCallUseCase = async ({
  callId,
  vendorId,
  startCall,
  endCall,
  duration,
  useCase,
  callClassification,
  callComment,
  callLaterHour,
  callLaterTime,
  callLogs,
  gapTotalValue,
}: UpsertOpenCallUseCaseProps): Promise<void> => {
  const params = {
    path: { callId },
    query: { useCase },
    headers: { vendorId },
  };

  if (useCase === OpenCallStateEnum.START_CALL) {
    agentCallSharedService().setStartCallState({
      startCallDate: startCall.toISOString(),
      callId,
    });

    await upsertOpenCall(params, {
      startCall,
    });
  }

  if (useCase === OpenCallStateEnum.END_CALL) {
    const missionsService = missionSharedService();
    const { missions } = missionsService.getStoreValue();

    const callMissions: CallMission[] = missions.map(
      ({ missionKey, type, effectiveness }) => ({
        missionKey,
        type,
        effectiveness: !!effectiveness,
      }),
    );

    await upsertOpenCall(params, {
      endCall,
      duration,
      callClassification,
      callComment,
      callLaterHour,
      callLaterTime,
      callLogs,
      missions: callMissions,
      gapTotalValue,
    });
  }
};
